import { useEffect } from 'react';

import { Map, MapMarker, ThemeProvider } from '@change/tomato-core-ui';

import { useTracking } from '@change-corgi/core/react/tracking';
import { Box } from '@change-corgi/design-system/layout';

import { usePetitionDetails } from '../../../../hooks/petitionDetails';
import { useSignaturesDistributionContext } from '../../shared/provider';
import type { SignaturesCluster } from '../../shared/types';

type Props = {
	signaturesClusters: SignaturesCluster[];
	clusterPercentage: number;
	isStaff: boolean;
};

export function SignatureMap({ signaturesClusters, clusterPercentage, isStaff }: Props): React.JSX.Element | null {
	const {
		center,
		contributors: [{ postalCode }],
	} = signaturesClusters[0];
	const {
		data: { clusterSize, zoomLevel },
	} = useSignaturesDistributionContext();
	const { id } = usePetitionDetails();
	const track = useTracking();

	useEffect(() => {
		void track('petition_sign_map_enabled', {
			petition_id: id,
			cluster_percentage: clusterPercentage,
			is_staff: isStaff,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!zoomLevel || !clusterSize) {
		return null;
	}

	return (
		<ThemeProvider>
			<Box
				data-qa="supporter-map"
				data-testid="div-around-sign-map"
				title={`map centered around zipcode ${postalCode}`}
				sx={{ width: '100%', height: 400, borderRadius: '10px' }}
			>
				<Map defaultCenter={{ lat: center.latitude, lng: center.longitude }} defaultZoom={zoomLevel}>
					<MapMarker radius={clusterSize * 100000} />
				</Map>
			</Box>
		</ThemeProvider>
	);
}
